{
  "antispoof": 853098,
  "blazeface": 538928,
  "centernet": 4030290,
  "emotion": 820516,
  "facemesh": 1477958,
  "faceres": 6978814,
  "handlandmark-lite": 2023432,
  "handtrack": 2964837,
  "iris": 2599092,
  "liveness": 592976,
  "models": 0,
  "movenet-lightning": 4650216,
  "affectnet-mobilenet": 6920630,
  "age": 161240,
  "blazeface-back": 538928,
  "blazeface-front": 402048,
  "blazepose-detector": 5928856,
  "blazepose-full": 6339202,
  "blazepose-heavy": 27502466,
  "blazepose-lite": 2726402,
  "efficientpose": 5651240,
  "faceboxes": 2013002,
  "facemesh-attention-pinto": 2387598,
  "facemesh-attention": 2382414,
  "facemesh-detection-full": 1026192,
  "facemesh-detection-short": 201268,
  "faceres-deep": 13957620,
  "gear-e1": 112438,
  "gear-e2": 112438,
  "gear": 1498916,
  "gender-ssrnet-imdb": 161236,
  "gender": 201808,
  "handdetect": 3515612,
  "handlandmark-full": 5431368,
  "handlandmark-sparse": 5286322,
  "handskeleton": 5502280,
  "meet": 372228,
  "mobileface": 2183192,
  "mobilefacenet": 5171976,
  "movenet-multipose": 9448838,
  "movenet-thunder": 12477112,
  "nanodet": 7574558,
  "posenet": 5032780,
  "rvm": 3739355,
  "selfie": 212886,
  "anti-spoofing": 853098,
  "efficientpose-i-lite": 2269064,
  "efficientpose-ii-lite": 5651240,
  "efficientpose-iv": 25643252,
  "insightface-efficientnet-b0": 13013224,
  "insightface-ghostnet-strides1": 8093408,
  "insightface-ghostnet-strides2": 8049584,
  "insightface-mobilenet-emore": 6938536,
  "insightface-mobilenet-swish": 12168584,
  "nanodet-e": 12319156,
  "nanodet-g": 7574558,
  "nanodet-m": 1887474,
  "nanodet-t": 5294216
}