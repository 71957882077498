/**
 * CoCo Labels used by object detection implementations
 */
export const labels = [
  { class: 1, label: 'person' },
  { class: 2, label: 'bicycle' },
  { class: 3, label: 'car' },
  { class: 4, label: 'motorcycle' },
  { class: 5, label: 'airplane' },
  { class: 6, label: 'bus' },
  { class: 7, label: 'train' },
  { class: 8, label: 'truck' },
  { class: 9, label: 'boat' },
  { class: 10, label: 'traffic light' },
  { class: 11, label: 'fire hydrant' },
  { class: 12, label: 'stop sign' },
  { class: 13, label: 'parking meter' },
  { class: 14, label: 'bench' },
  { class: 15, label: 'bird' },
  { class: 16, label: 'cat' },
  { class: 17, label: 'dog' },
  { class: 18, label: 'horse' },
  { class: 19, label: 'sheep' },
  { class: 20, label: 'cow' },
  { class: 21, label: 'elephant' },
  { class: 22, label: 'bear' },
  { class: 23, label: 'zebra' },
  { class: 24, label: 'giraffe' },
  { class: 25, label: 'backpack' },
  { class: 26, label: 'umbrella' },
  { class: 27, label: 'handbag' },
  { class: 28, label: 'tie' },
  { class: 29, label: 'suitcase' },
  { class: 30, label: 'frisbee' },
  { class: 31, label: 'skis' },
  { class: 32, label: 'snowboard' },
  { class: 33, label: 'sports ball' },
  { class: 34, label: 'kite' },
  { class: 35, label: 'baseball bat' },
  { class: 36, label: 'baseball glove' },
  { class: 37, label: 'skateboard' },
  { class: 38, label: 'surfboard' },
  { class: 39, label: 'tennis racket' },
  { class: 40, label: 'bottle' },
  { class: 41, label: 'wine glass' },
  { class: 42, label: 'cup' },
  { class: 43, label: 'fork' },
  { class: 44, label: 'knife' },
  { class: 45, label: 'spoon' },
  { class: 46, label: 'bowl' },
  { class: 47, label: 'banana' },
  { class: 48, label: 'apple' },
  { class: 49, label: 'sandwich' },
  { class: 50, label: 'orange' },
  { class: 51, label: 'broccoli' },
  { class: 52, label: 'carrot' },
  { class: 53, label: 'hot dog' },
  { class: 54, label: 'pizza' },
  { class: 55, label: 'donut' },
  { class: 56, label: 'cake' },
  { class: 57, label: 'chair' },
  { class: 58, label: 'couch' },
  { class: 59, label: 'potted plant' },
  { class: 60, label: 'bed' },
  { class: 61, label: 'dining table' },
  { class: 62, label: 'toilet' },
  { class: 63, label: 'tv' },
  { class: 64, label: 'laptop' },
  { class: 65, label: 'mouse' },
  { class: 66, label: 'remote' },
  { class: 67, label: 'keyboard' },
  { class: 68, label: 'cell phone' },
  { class: 69, label: 'microwave' },
  { class: 70, label: 'oven' },
  { class: 71, label: 'toaster' },
  { class: 72, label: 'sink' },
  { class: 73, label: 'refrigerator' },
  { class: 74, label: 'book' },
  { class: 75, label: 'clock' },
  { class: 76, label: 'vase' },
  { class: 77, label: 'scissors' },
  { class: 78, label: 'teddy bear' },
  { class: 79, label: 'hair drier' },
  { class: 80, label: 'toothbrush' },
];
