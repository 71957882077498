/**
 * BlazeFace, FaceMesh & Iris model implementation
 * See `facemesh.ts` for entry point
 */

export const meshAnnotations: Record<string, number[]> = {
  silhouette: [
    10, 338, 297, 332, 284, 251, 389, 356, 454, 323, 361, 288,
    397, 365, 379, 378, 400, 377, 152, 148, 176, 149, 150, 136,
    172, 58, 132, 93, 234, 127, 162, 21, 54, 103, 67, 109,
  ],
  // lipsUpperOuter: [61, 185, 40, 39, 37, 0, 267, 269, 270, 409, 291], // 11
  // lipsLowerOuter: [146, 91, 181, 84, 17, 314, 405, 321, 375, 291], // 10
  // lipsUpperInner: [78, 191, 80, 81, 82, 13, 312, 311, 310, 415, 308], // 11
  // lipsLowerInner: [78, 95, 88, 178, 87, 14, 317, 402, 318, 324, 308], // 11
  lipsUpperOuter: [185, 40, 39, 37, 0, 267, 269, 270, 409],
  lipsLowerOuter: [61, 146, 91, 181, 84, 17, 314, 405, 321, 375, 291],
  lipsUpperInner: [191, 80, 81, 82, 13, 312, 311, 310, 415],
  lipsLowerInner: [78, 95, 88, 178, 87, 14, 317, 402, 318, 324, 308],
  lipsLowerSemiOuter: [76, 77, 90, 180, 85, 16, 315, 404, 320, 307, 306],
  lipsUpperSemiOuter: [184, 74, 73, 72, 11, 302, 303, 304, 408],
  lipsLowerSemiInner: [62, 96, 89, 179, 86, 15, 316, 403, 319, 325, 292],
  lipsUpperSemiInner: [183, 42, 41, 38, 12, 268, 271, 272, 407],
  rightEyeUpper0: [246, 161, 160, 159, 158, 157, 173], // 7
  rightEyeLower0: [33, 7, 163, 144, 145, 153, 154, 155, 133], // 9
  rightEyeUpper1: [247, 30, 29, 27, 28, 56, 190], // 7
  rightEyeLower1: [130, 25, 110, 24, 23, 22, 26, 112, 243], // 9
  rightEyeUpper2: [113, 225, 224, 223, 222, 221, 189], // 7
  rightEyeLower2: [226, 31, 228, 229, 230, 231, 232, 233, 244], // 9
  rightEyeLower3: [143, 111, 117, 118, 119, 120, 121, 128, 245], // 9
  rightEyebrowUpper: [156, 70, 63, 105, 66, 107, 55, 193], // 8
  rightEyebrowLower: [35, 124, 46, 53, 52, 65], // 6
  rightEyeIris: [473, 474, 475, 476, 477], // 5
  leftEyeUpper0: [466, 388, 387, 386, 385, 384, 398],
  leftEyeLower0: [263, 249, 390, 373, 374, 380, 381, 382, 362],
  leftEyeUpper1: [467, 260, 259, 257, 258, 286, 414],
  leftEyeLower1: [359, 255, 339, 254, 253, 252, 256, 341, 463],
  leftEyeUpper2: [342, 445, 444, 443, 442, 441, 413],
  leftEyeLower2: [446, 261, 448, 449, 450, 451, 452, 453, 464],
  leftEyeLower3: [372, 340, 346, 347, 348, 349, 350, 357, 465],
  leftEyebrowUpper: [383, 300, 293, 334, 296, 336, 285, 417],
  leftEyebrowLower: [265, 353, 276, 283, 282, 295],
  leftEyeIris: [468, 469, 470, 471, 472],
  midwayBetweenEyes: [168],
  noseTip: [1],
  noseBottom: [2],
  noseRightCorner: [98],
  noseLeftCorner: [327],
  rightCheek: [205],
  leftCheek: [425],
};

export const meshLandmarks: Record<string, number | number[]> = {
  count: 468,
  mouth: 13,
  symmetryLine: [13, meshAnnotations.midwayBetweenEyes[0]],
};

export const blazeFaceLandmarks: Record<string, number | number[]> = {
  leftEye: 0,
  rightEye: 1,
  nose: 2,
  mouth: 3,
  leftEar: 4,
  rightEar: 5,
  symmetryLine: [3, 2],
};

export const irisIndices: { key: string, indices: number[] }[] = [ // A mapping from facemesh model keypoints to iris model keypoints.
  { key: 'EyeUpper0', indices: [9, 10, 11, 12, 13, 14, 15] }, // 7 x 3d
  { key: 'EyeUpper1', indices: [25, 26, 27, 28, 29, 30, 31] }, // 7 x 3d
  { key: 'EyeUpper2', indices: [41, 42, 43, 44, 45, 46, 47] }, // 7 x 3d
  { key: 'EyeLower0', indices: [0, 1, 2, 3, 4, 5, 6, 7, 8] }, // 7 x 3d
  { key: 'EyeLower1', indices: [16, 17, 18, 19, 20, 21, 22, 23, 24] }, // 9 x 3d
  { key: 'EyeLower2', indices: [32, 33, 34, 35, 36, 37, 38, 39, 40] }, // 9 x 3d
  { key: 'EyeLower3', indices: [54, 55, 56, 57, 58, 59, 60, 61, 62] }, // 9 x 3d
  { key: 'EyebrowUpper', indices: [63, 64, 65, 66, 67, 68, 69, 70] }, // 8 x 3d
  { key: 'EyebrowLower', indices: [48, 49, 50, 51, 52, 53] }, // 6 x 3d
];

export const UV468: [number, number][] = [
  [0.499976992607117, 0.652534008026123],
  [0.500025987625122, 0.547487020492554],
  [0.499974012374878, 0.602371990680695],
  [0.482113003730774, 0.471979022026062],
  [0.500150978565216, 0.527155995368958],
  [0.499909996986389, 0.498252987861633],
  [0.499523013830185, 0.40106201171875],
  [0.289712011814117, 0.380764007568359],
  [0.499954998493195, 0.312398016452789],
  [0.499987006187439, 0.269918978214264],
  [0.500023007392883, 0.107050001621246],
  [0.500023007392883, 0.666234016418457],
  [0.5000159740448, 0.679224014282227],
  [0.500023007392883, 0.692348003387451],
  [0.499976992607117, 0.695277988910675],
  [0.499976992607117, 0.70593398809433],
  [0.499976992607117, 0.719385027885437],
  [0.499976992607117, 0.737019002437592],
  [0.499967992305756, 0.781370997428894],
  [0.499816000461578, 0.562981009483337],
  [0.473773002624512, 0.573909997940063],
  [0.104906998574734, 0.254140973091125],
  [0.365929991006851, 0.409575998783112],
  [0.338757991790771, 0.41302502155304],
  [0.311120003461838, 0.409460008144379],
  [0.274657994508743, 0.389131009578705],
  [0.393361985683441, 0.403706014156342],
  [0.345234006643295, 0.344011008739471],
  [0.370094001293182, 0.346076011657715],
  [0.319321990013123, 0.347265005111694],
  [0.297903001308441, 0.353591024875641],
  [0.24779200553894, 0.410809993743896],
  [0.396889001131058, 0.842755019664764],
  [0.280097991228104, 0.375599980354309],
  [0.106310002505779, 0.399955987930298],
  [0.2099249958992, 0.391353011131287],
  [0.355807989835739, 0.534406006336212],
  [0.471751004457474, 0.65040397644043],
  [0.474155008792877, 0.680191993713379],
  [0.439785003662109, 0.657229006290436],
  [0.414617002010345, 0.66654098033905],
  [0.450374007225037, 0.680860996246338],
  [0.428770989179611, 0.682690978050232],
  [0.374971002340317, 0.727805018424988],
  [0.486716985702515, 0.547628998756409],
  [0.485300987958908, 0.527395009994507],
  [0.257764995098114, 0.314490020275116],
  [0.401223003864288, 0.455172002315521],
  [0.429818987846375, 0.548614978790283],
  [0.421351999044418, 0.533740997314453],
  [0.276895999908447, 0.532056987285614],
  [0.483370006084442, 0.499586999416351],
  [0.33721199631691, 0.282882988452911],
  [0.296391993761063, 0.293242990970612],
  [0.169294998049736, 0.193813979625702],
  [0.447580009698868, 0.302609980106354],
  [0.392390012741089, 0.353887975215912],
  [0.354490011930466, 0.696784019470215],
  [0.067304998636246, 0.730105042457581],
  [0.442739009857178, 0.572826027870178],
  [0.457098007202148, 0.584792017936707],
  [0.381974011659622, 0.694710969924927],
  [0.392388999462128, 0.694203019142151],
  [0.277076005935669, 0.271932005882263],
  [0.422551989555359, 0.563233017921448],
  [0.385919004678726, 0.281364023685455],
  [0.383103013038635, 0.255840003490448],
  [0.331431001424789, 0.119714021682739],
  [0.229923993349075, 0.232002973556519],
  [0.364500999450684, 0.189113974571228],
  [0.229622006416321, 0.299540996551514],
  [0.173287004232407, 0.278747975826263],
  [0.472878992557526, 0.666198015213013],
  [0.446828007698059, 0.668527007102966],
  [0.422762006521225, 0.673889994621277],
  [0.445307999849319, 0.580065965652466],
  [0.388103008270264, 0.693961024284363],
  [0.403039008378983, 0.706539988517761],
  [0.403629004955292, 0.693953037261963],
  [0.460041999816895, 0.557139039039612],
  [0.431158006191254, 0.692366003990173],
  [0.452181994915009, 0.692366003990173],
  [0.475387006998062, 0.692366003990173],
  [0.465828001499176, 0.779190003871918],
  [0.472328990697861, 0.736225962638855],
  [0.473087012767792, 0.717857003211975],
  [0.473122000694275, 0.704625964164734],
  [0.473033010959625, 0.695277988910675],
  [0.427942007780075, 0.695277988910675],
  [0.426479011774063, 0.703539967536926],
  [0.423162013292313, 0.711845993995667],
  [0.4183090031147, 0.720062971115112],
  [0.390094995498657, 0.639572978019714],
  [0.013953999616206, 0.560034036636353],
  [0.499913990497589, 0.58014702796936],
  [0.413199990987778, 0.69539999961853],
  [0.409626007080078, 0.701822996139526],
  [0.468080013990402, 0.601534962654114],
  [0.422728985548019, 0.585985004901886],
  [0.463079988956451, 0.593783974647522],
  [0.37211999297142, 0.47341400384903],
  [0.334562003612518, 0.496073007583618],
  [0.411671012639999, 0.546965003013611],
  [0.242175996303558, 0.14767599105835],
  [0.290776997804642, 0.201445996761322],
  [0.327338010072708, 0.256527006626129],
  [0.399509996175766, 0.748921036720276],
  [0.441727995872498, 0.261676013469696],
  [0.429764986038208, 0.187834024429321],
  [0.412198007106781, 0.108901023864746],
  [0.288955003023148, 0.398952007293701],
  [0.218936994671822, 0.435410976409912],
  [0.41278201341629, 0.398970007896423],
  [0.257135003805161, 0.355440020561218],
  [0.427684992551804, 0.437960982322693],
  [0.448339998722076, 0.536936044692993],
  [0.178560003638268, 0.45755398273468],
  [0.247308000922203, 0.457193970680237],
  [0.286267012357712, 0.467674970626831],
  [0.332827985286713, 0.460712015628815],
  [0.368755996227264, 0.447206974029541],
  [0.398963987827301, 0.432654976844788],
  [0.476410001516342, 0.405806005001068],
  [0.189241006970406, 0.523923993110657],
  [0.228962004184723, 0.348950982093811],
  [0.490725994110107, 0.562400996685028],
  [0.404670000076294, 0.485132992267609],
  [0.019469000399113, 0.401564002037048],
  [0.426243007183075, 0.420431017875671],
  [0.396993011236191, 0.548797011375427],
  [0.266469985246658, 0.376977026462555],
  [0.439121007919312, 0.51895797252655],
  [0.032313998788595, 0.644356966018677],
  [0.419054001569748, 0.387154996395111],
  [0.462783008813858, 0.505746960639954],
  [0.238978996872902, 0.779744982719421],
  [0.198220998048782, 0.831938028335571],
  [0.107550002634525, 0.540755033493042],
  [0.183610007166862, 0.740257024765015],
  [0.134409993886948, 0.333683013916016],
  [0.385764002799988, 0.883153975009918],
  [0.490967005491257, 0.579378008842468],
  [0.382384985685349, 0.508572995662689],
  [0.174399003386497, 0.397670984268188],
  [0.318785011768341, 0.39623498916626],
  [0.343364000320435, 0.400596976280212],
  [0.396100014448166, 0.710216999053955],
  [0.187885001301765, 0.588537991046906],
  [0.430987000465393, 0.944064974784851],
  [0.318993002176285, 0.898285031318665],
  [0.266247987747192, 0.869701027870178],
  [0.500023007392883, 0.190576016902924],
  [0.499976992607117, 0.954452991485596],
  [0.366169989109039, 0.398822009563446],
  [0.393207013607025, 0.39553701877594],
  [0.410373002290726, 0.391080021858215],
  [0.194993004202843, 0.342101991176605],
  [0.388664990663528, 0.362284004688263],
  [0.365961998701096, 0.355970978736877],
  [0.343364000320435, 0.355356991291046],
  [0.318785011768341, 0.35834002494812],
  [0.301414996385574, 0.363156020641327],
  [0.058132998645306, 0.319076001644135],
  [0.301414996385574, 0.387449026107788],
  [0.499987989664078, 0.618434011936188],
  [0.415838003158569, 0.624195992946625],
  [0.445681989192963, 0.566076993942261],
  [0.465844005346298, 0.620640993118286],
  [0.49992299079895, 0.351523995399475],
  [0.288718998432159, 0.819945991039276],
  [0.335278987884521, 0.852819979190826],
  [0.440512001514435, 0.902418971061707],
  [0.128294005990028, 0.791940987110138],
  [0.408771991729736, 0.373893976211548],
  [0.455606997013092, 0.451801002025604],
  [0.499877005815506, 0.908990025520325],
  [0.375436991453171, 0.924192011356354],
  [0.11421000212431, 0.615022003650665],
  [0.448662012815475, 0.695277988910675],
  [0.4480200111866, 0.704632043838501],
  [0.447111994028091, 0.715808033943176],
  [0.444831997156143, 0.730794012546539],
  [0.430011987686157, 0.766808986663818],
  [0.406787008047104, 0.685672998428345],
  [0.400738000869751, 0.681069016456604],
  [0.392399996519089, 0.677703022956848],
  [0.367855995893478, 0.663918972015381],
  [0.247923001646996, 0.601333022117615],
  [0.452769994735718, 0.420849978923798],
  [0.43639200925827, 0.359887003898621],
  [0.416164010763168, 0.368713974952698],
  [0.413385987281799, 0.692366003990173],
  [0.228018000721931, 0.683571994304657],
  [0.468268007040024, 0.352671027183533],
  [0.411361992359161, 0.804327011108398],
  [0.499989002943039, 0.469825029373169],
  [0.479153990745544, 0.442654013633728],
  [0.499974012374878, 0.439637005329132],
  [0.432112008333206, 0.493588984012604],
  [0.499886006116867, 0.866917014122009],
  [0.49991300702095, 0.821729004383087],
  [0.456548988819122, 0.819200992584229],
  [0.344549000263214, 0.745438992977142],
  [0.37890899181366, 0.574010014533997],
  [0.374292999505997, 0.780184984207153],
  [0.319687992334366, 0.570737957954407],
  [0.357154995203018, 0.604269981384277],
  [0.295284003019333, 0.621580958366394],
  [0.447750002145767, 0.862477004528046],
  [0.410986006259918, 0.508723020553589],
  [0.31395098567009, 0.775308012962341],
  [0.354128003120422, 0.812552988529205],
  [0.324548006057739, 0.703992962837219],
  [0.189096003770828, 0.646299958229065],
  [0.279776990413666, 0.71465802192688],
  [0.1338230073452, 0.682700991630554],
  [0.336768001317978, 0.644733011722565],
  [0.429883986711502, 0.466521978378296],
  [0.455527991056442, 0.548622965812683],
  [0.437114000320435, 0.558896005153656],
  [0.467287987470627, 0.529924988746643],
  [0.414712011814117, 0.335219979286194],
  [0.37704598903656, 0.322777986526489],
  [0.344107985496521, 0.320150971412659],
  [0.312875986099243, 0.32233202457428],
  [0.283526003360748, 0.333190023899078],
  [0.241245999932289, 0.382785975933075],
  [0.102986000478268, 0.468762993812561],
  [0.267612010240555, 0.424560010433197],
  [0.297879010438919, 0.433175981044769],
  [0.333433985710144, 0.433878004550934],
  [0.366427004337311, 0.426115989685059],
  [0.396012008190155, 0.416696012020111],
  [0.420121014118195, 0.41022801399231],
  [0.007561000064015, 0.480777025222778],
  [0.432949006557465, 0.569517970085144],
  [0.458638995885849, 0.479089021682739],
  [0.473466008901596, 0.545744001865387],
  [0.476087987422943, 0.563830018043518],
  [0.468472003936768, 0.555056989192963],
  [0.433990985155106, 0.582361996173859],
  [0.483518004417419, 0.562983989715576],
  [0.482482999563217, 0.57784903049469],
  [0.42645001411438, 0.389798998832703],
  [0.438998997211456, 0.39649498462677],
  [0.450067013502121, 0.400434017181396],
  [0.289712011814117, 0.368252992630005],
  [0.276670008897781, 0.363372981548309],
  [0.517862021923065, 0.471948027610779],
  [0.710287988185883, 0.380764007568359],
  [0.526226997375488, 0.573909997940063],
  [0.895093023777008, 0.254140973091125],
  [0.634069979190826, 0.409575998783112],
  [0.661242008209229, 0.41302502155304],
  [0.688880026340485, 0.409460008144379],
  [0.725341975688934, 0.389131009578705],
  [0.606630027294159, 0.40370500087738],
  [0.654766023159027, 0.344011008739471],
  [0.629905998706818, 0.346076011657715],
  [0.680678009986877, 0.347265005111694],
  [0.702096998691559, 0.353591024875641],
  [0.75221198797226, 0.410804986953735],
  [0.602918028831482, 0.842862963676453],
  [0.719901978969574, 0.375599980354309],
  [0.893692970275879, 0.399959981441498],
  [0.790081977844238, 0.391354024410248],
  [0.643998026847839, 0.534487962722778],
  [0.528249025344849, 0.65040397644043],
  [0.525849997997284, 0.680191040039062],
  [0.560214996337891, 0.657229006290436],
  [0.585384011268616, 0.66654098033905],
  [0.549625992774963, 0.680860996246338],
  [0.57122802734375, 0.682691991329193],
  [0.624852001667023, 0.72809898853302],
  [0.513050019741058, 0.547281980514526],
  [0.51509702205658, 0.527251958847046],
  [0.742246985435486, 0.314507007598877],
  [0.598631024360657, 0.454979002475739],
  [0.570338010787964, 0.548575043678284],
  [0.578631997108459, 0.533622980117798],
  [0.723087012767792, 0.532054007053375],
  [0.516445994377136, 0.499638974666595],
  [0.662801027297974, 0.282917976379395],
  [0.70362401008606, 0.293271005153656],
  [0.830704987049103, 0.193813979625702],
  [0.552385985851288, 0.302568018436432],
  [0.607609987258911, 0.353887975215912],
  [0.645429015159607, 0.696707010269165],
  [0.932694971561432, 0.730105042457581],
  [0.557260990142822, 0.572826027870178],
  [0.542901992797852, 0.584792017936707],
  [0.6180260181427, 0.694710969924927],
  [0.607590973377228, 0.694203019142151],
  [0.722943007946014, 0.271963000297546],
  [0.577413976192474, 0.563166975975037],
  [0.614082992076874, 0.281386971473694],
  [0.616907000541687, 0.255886018276215],
  [0.668509006500244, 0.119913995265961],
  [0.770092010498047, 0.232020974159241],
  [0.635536015033722, 0.189248979091644],
  [0.77039098739624, 0.299556016921997],
  [0.826722025871277, 0.278755009174347],
  [0.527121007442474, 0.666198015213013],
  [0.553171992301941, 0.668527007102966],
  [0.577238023281097, 0.673889994621277],
  [0.554691970348358, 0.580065965652466],
  [0.611896991729736, 0.693961024284363],
  [0.59696102142334, 0.706539988517761],
  [0.596370995044708, 0.693953037261963],
  [0.539958000183105, 0.557139039039612],
  [0.568841993808746, 0.692366003990173],
  [0.547818005084991, 0.692366003990173],
  [0.52461302280426, 0.692366003990173],
  [0.534089982509613, 0.779141008853912],
  [0.527670979499817, 0.736225962638855],
  [0.526912987232208, 0.717857003211975],
  [0.526877999305725, 0.704625964164734],
  [0.526966989040375, 0.695277988910675],
  [0.572058022022247, 0.695277988910675],
  [0.573521018028259, 0.703539967536926],
  [0.57683801651001, 0.711845993995667],
  [0.581691026687622, 0.720062971115112],
  [0.609944999217987, 0.639909982681274],
  [0.986046016216278, 0.560034036636353],
  [0.5867999792099, 0.69539999961853],
  [0.590372025966644, 0.701822996139526],
  [0.531915009021759, 0.601536989212036],
  [0.577268004417419, 0.585934996604919],
  [0.536915004253387, 0.593786001205444],
  [0.627542972564697, 0.473352015018463],
  [0.665585994720459, 0.495950996875763],
  [0.588353991508484, 0.546862006187439],
  [0.757824003696442, 0.14767599105835],
  [0.709249973297119, 0.201507985591888],
  [0.672684013843536, 0.256581008434296],
  [0.600408971309662, 0.74900496006012],
  [0.55826598405838, 0.261672019958496],
  [0.570303976535797, 0.187870979309082],
  [0.588165998458862, 0.109044015407562],
  [0.711045026779175, 0.398952007293701],
  [0.781069993972778, 0.435405015945435],
  [0.587247014045715, 0.398931980133057],
  [0.742869973182678, 0.355445981025696],
  [0.572156012058258, 0.437651991844177],
  [0.55186802148819, 0.536570012569427],
  [0.821442008018494, 0.457556009292603],
  [0.752701997756958, 0.457181990146637],
  [0.71375697851181, 0.467626988887787],
  [0.66711300611496, 0.460672974586487],
  [0.631101012229919, 0.447153985500336],
  [0.6008620262146, 0.432473003864288],
  [0.523481011390686, 0.405627012252808],
  [0.810747981071472, 0.523926019668579],
  [0.771045982837677, 0.348959028720856],
  [0.509127020835876, 0.562718033790588],
  [0.595292985439301, 0.485023975372314],
  [0.980530977249146, 0.401564002037048],
  [0.573499977588654, 0.420000016689301],
  [0.602994978427887, 0.548687994480133],
  [0.733529984951019, 0.376977026462555],
  [0.560611009597778, 0.519016981124878],
  [0.967685997486115, 0.644356966018677],
  [0.580985009670258, 0.387160003185272],
  [0.537728011608124, 0.505385041236877],
  [0.760966002941132, 0.779752969741821],
  [0.801778972148895, 0.831938028335571],
  [0.892440974712372, 0.54076099395752],
  [0.816350996494293, 0.740260004997253],
  [0.865594983100891, 0.333687007427216],
  [0.614073991775513, 0.883246004581451],
  [0.508952975273132, 0.579437971115112],
  [0.617941975593567, 0.508316040039062],
  [0.825608015060425, 0.397674977779388],
  [0.681214988231659, 0.39623498916626],
  [0.656635999679565, 0.400596976280212],
  [0.603900015354156, 0.710216999053955],
  [0.81208598613739, 0.588539004325867],
  [0.56801301240921, 0.944564998149872],
  [0.681007981300354, 0.898285031318665],
  [0.733752012252808, 0.869701027870178],
  [0.633830010890961, 0.398822009563446],
  [0.606792986392975, 0.39553701877594],
  [0.589659988880157, 0.391062021255493],
  [0.805015981197357, 0.342108011245728],
  [0.611334979534149, 0.362284004688263],
  [0.634037971496582, 0.355970978736877],
  [0.656635999679565, 0.355356991291046],
  [0.681214988231659, 0.35834002494812],
  [0.698584973812103, 0.363156020641327],
  [0.941866993904114, 0.319076001644135],
  [0.698584973812103, 0.387449026107788],
  [0.584177017211914, 0.624107003211975],
  [0.554318010807037, 0.566076993942261],
  [0.534153997898102, 0.62064003944397],
  [0.711217999458313, 0.819975018501282],
  [0.664629995822906, 0.852871000766754],
  [0.559099972248077, 0.902631998062134],
  [0.871706008911133, 0.791940987110138],
  [0.591234028339386, 0.373893976211548],
  [0.544341027736664, 0.451583981513977],
  [0.624562978744507, 0.924192011356354],
  [0.88577002286911, 0.615028977394104],
  [0.551338016986847, 0.695277988910675],
  [0.551980018615723, 0.704632043838501],
  [0.552887976169586, 0.715808033943176],
  [0.555167973041534, 0.730794012546539],
  [0.569944024085999, 0.767035007476807],
  [0.593203008174896, 0.685675978660583],
  [0.599261999130249, 0.681069016456604],
  [0.607599973678589, 0.677703022956848],
  [0.631937980651855, 0.663500010967255],
  [0.752032995223999, 0.601315021514893],
  [0.547226011753082, 0.420395016670227],
  [0.563543975353241, 0.359827995300293],
  [0.583841025829315, 0.368713974952698],
  [0.586614012718201, 0.692366003990173],
  [0.771915018558502, 0.683578014373779],
  [0.531597018241882, 0.352482974529266],
  [0.588370978832245, 0.804440975189209],
  [0.52079701423645, 0.442565023899078],
  [0.567984998226166, 0.493479013442993],
  [0.543282985687256, 0.819254994392395],
  [0.655317008495331, 0.745514988899231],
  [0.621008992195129, 0.574018001556396],
  [0.625559985637665, 0.78031200170517],
  [0.680198013782501, 0.570719003677368],
  [0.64276397228241, 0.604337990283966],
  [0.704662978649139, 0.621529996395111],
  [0.552012026309967, 0.862591981887817],
  [0.589071989059448, 0.508637011051178],
  [0.685944974422455, 0.775357007980347],
  [0.645735025405884, 0.812640011310577],
  [0.675342977046967, 0.703978002071381],
  [0.810858011245728, 0.646304965019226],
  [0.72012197971344, 0.714666962623596],
  [0.866151988506317, 0.682704985141754],
  [0.663187026977539, 0.644596993923187],
  [0.570082008838654, 0.466325998306274],
  [0.544561982154846, 0.548375964164734],
  [0.562758982181549, 0.558784961700439],
  [0.531987011432648, 0.530140042304993],
  [0.585271000862122, 0.335177004337311],
  [0.622952997684479, 0.32277899980545],
  [0.655896008014679, 0.320163011550903],
  [0.687132000923157, 0.322345972061157],
  [0.716481983661652, 0.333200991153717],
  [0.758756995201111, 0.382786989212036],
  [0.897013008594513, 0.468769013881683],
  [0.732392013072968, 0.424547016620636],
  [0.70211398601532, 0.433162987232208],
  [0.66652500629425, 0.433866024017334],
  [0.633504986763, 0.426087975502014],
  [0.603875994682312, 0.416586995124817],
  [0.579657971858978, 0.409945011138916],
  [0.992439985275269, 0.480777025222778],
  [0.567192018032074, 0.569419980049133],
  [0.54136598110199, 0.478899002075195],
  [0.526564002037048, 0.546118021011353],
  [0.523913025856018, 0.563830018043518],
  [0.531529009342194, 0.555056989192963],
  [0.566035985946655, 0.582329034805298],
  [0.51631098985672, 0.563053965568542],
  [0.5174720287323, 0.577877044677734],
  [0.573594987392426, 0.389806985855103],
  [0.560697972774506, 0.395331978797913],
  [0.549755990505219, 0.399751007556915],
  [0.710287988185883, 0.368252992630005],
  [0.723330020904541, 0.363372981548309],
];

export const TRI468: number[] = [
  127, 34, 139, 11, 0, 37, 232, 231, 120, 72, 37, 39, 128, 121, 47, 232, 121, 128, 104, 69, 67, 175, 171, 148, 157, 154, 155, 118, 50, 101, 73, 39, 40, 9,
  151, 108, 48, 115, 131, 194, 204, 211, 74, 40, 185, 80, 42, 183, 40, 92, 186, 230, 229, 118, 202, 212, 214, 83, 18, 17, 76, 61, 146, 160, 29, 30, 56,
  157, 173, 106, 204, 194, 135, 214, 192, 203, 165, 98, 21, 71, 68, 51, 45, 4, 144, 24, 23, 77, 146, 91, 205, 50, 187, 201, 200, 18, 91, 106, 182, 90, 91,
  181, 85, 84, 17, 206, 203, 36, 148, 171, 140, 92, 40, 39, 193, 189, 244, 159, 158, 28, 247, 246, 161, 236, 3, 196, 54, 68, 104, 193, 168, 8, 117,
  228, 31, 189, 193, 55, 98, 97, 99, 126, 47, 100, 166, 79, 218, 155, 154, 26, 209, 49, 131, 135, 136, 150, 47, 126, 217, 223, 52, 53, 45, 51, 134, 211,
  170, 140, 67, 69, 108, 43, 106, 91, 230, 119, 120, 226, 130, 247, 63, 53, 52, 238, 20, 242, 46, 70, 156, 78, 62, 96, 46, 53, 63, 143, 34, 227, 173,
  155, 133, 123, 117, 111, 44, 125, 19, 236, 134, 51, 216, 206, 205, 154, 153, 22, 39, 37, 167, 200, 201, 208, 36, 142, 100, 57, 212, 202, 20, 60, 99, 28,
  158, 157, 35, 226, 113, 160, 159, 27, 204, 202, 210, 113, 225, 46, 43, 202, 204, 62, 76, 77, 137, 123, 116, 41, 38, 72, 203, 129, 142, 64, 98, 240, 49,
  102, 64, 41, 73, 74, 212, 216, 207, 42, 74, 184, 169, 170, 211, 170, 149, 176, 105, 66, 69, 122, 6, 168, 123, 147, 187, 96, 77, 90, 65, 55, 107, 89,
  90, 180, 101, 100, 120, 63, 105, 104, 93, 137, 227, 15, 86, 85, 129, 102, 49, 14, 87, 86, 55, 8, 9, 100, 47, 121, 145, 23, 22, 88, 89, 179, 6, 122,
  196, 88, 95, 96, 138, 172, 136, 215, 58, 172, 115, 48, 219, 42, 80, 81, 195, 3, 51, 43, 146, 61, 171, 175, 199, 81, 82, 38, 53, 46, 225, 144, 163, 110,
  246, 33, 7, 52, 65, 66, 229, 228, 117, 34, 127, 234, 107, 108, 69, 109, 108, 151, 48, 64, 235, 62, 78, 191, 129, 209, 126, 111, 35, 143, 163, 161, 246,
  117, 123, 50, 222, 65, 52, 19, 125, 141, 221, 55, 65, 3, 195, 197, 25, 7, 33, 220, 237, 44, 70, 71, 139, 122, 193, 245, 247, 130, 33, 71, 21, 162,
  153, 158, 159, 170, 169, 150, 188, 174, 196, 216, 186, 92, 144, 160, 161, 2, 97, 167, 141, 125, 241, 164, 167, 37, 72, 38, 12, 145, 159, 160, 38, 82, 13,
  63, 68, 71, 226, 35, 111, 158, 153, 154, 101, 50, 205, 206, 92, 165, 209, 198, 217, 165, 167, 97, 220, 115, 218, 133, 112, 243, 239, 238, 241, 214,
  135, 169, 190, 173, 133, 171, 208, 32, 125, 44, 237, 86, 87, 178, 85, 86, 179, 84, 85, 180, 83, 84, 181, 201, 83, 182, 137, 93, 132, 76, 62, 183, 61,
  76, 184, 57, 61, 185, 212, 57, 186, 214, 207, 187, 34, 143, 156, 79, 239, 237, 123, 137, 177, 44, 1, 4, 201, 194, 32, 64, 102, 129, 213, 215, 138, 59,
  166, 219, 242, 99, 97, 2, 94, 141, 75, 59, 235, 24, 110, 228, 25, 130, 226, 23, 24, 229, 22, 23, 230, 26, 22, 231, 112, 26, 232, 189, 190, 243, 221, 56,
  190, 28, 56, 221, 27, 28, 222, 29, 27, 223, 30, 29, 224, 247, 30, 225, 238, 79, 20, 166, 59, 75, 60, 75, 240, 147, 177, 215, 20, 79, 166, 187, 147, 213,
  112, 233, 244, 233, 128, 245, 128, 114, 188, 114, 217, 174, 131, 115, 220, 217, 198, 236, 198, 131, 134, 177, 132, 58, 143, 35, 124, 110, 163, 7, 228,
  110, 25, 356, 389, 368, 11, 302, 267, 452, 350, 349, 302, 303, 269, 357, 343, 277, 452, 453, 357, 333, 332, 297, 175, 152, 377, 384, 398, 382, 347,
  348, 330, 303, 304, 270, 9, 336, 337, 278, 279, 360, 418, 262, 431, 304, 408, 409, 310, 415, 407, 270, 409, 410, 450, 348, 347, 422, 430, 434, 313,
  314, 17, 306, 307, 375, 387, 388, 260, 286, 414, 398, 335, 406, 418, 364, 367, 416, 423, 358, 327, 251, 284, 298, 281, 5, 4, 373, 374, 253, 307, 320,
  321, 425, 427, 411, 421, 313, 18, 321, 405, 406, 320, 404, 405, 315, 16, 17, 426, 425, 266, 377, 400, 369, 322, 391, 269, 417, 465, 464, 386, 257, 258,
  466, 260, 388, 456, 399, 419, 284, 332, 333, 417, 285, 8, 346, 340, 261, 413, 441, 285, 327, 460, 328, 355, 371, 329, 392, 439, 438, 382, 341, 256,
  429, 420, 360, 364, 394, 379, 277, 343, 437, 443, 444, 283, 275, 440, 363, 431, 262, 369, 297, 338, 337, 273, 375, 321, 450, 451, 349, 446, 342, 467,
  293, 334, 282, 458, 461, 462, 276, 353, 383, 308, 324, 325, 276, 300, 293, 372, 345, 447, 382, 398, 362, 352, 345, 340, 274, 1, 19, 456, 248, 281, 436,
  427, 425, 381, 256, 252, 269, 391, 393, 200, 199, 428, 266, 330, 329, 287, 273, 422, 250, 462, 328, 258, 286, 384, 265, 353, 342, 387, 259, 257, 424,
  431, 430, 342, 353, 276, 273, 335, 424, 292, 325, 307, 366, 447, 345, 271, 303, 302, 423, 266, 371, 294, 455, 460, 279, 278, 294, 271, 272, 304, 432,
  434, 427, 272, 407, 408, 394, 430, 431, 395, 369, 400, 334, 333, 299, 351, 417, 168, 352, 280, 411, 325, 319, 320, 295, 296, 336, 319, 403, 404, 330,
  348, 349, 293, 298, 333, 323, 454, 447, 15, 16, 315, 358, 429, 279, 14, 15, 316, 285, 336, 9, 329, 349, 350, 374, 380, 252, 318, 402, 403, 6, 197, 419,
  318, 319, 325, 367, 364, 365, 435, 367, 397, 344, 438, 439, 272, 271, 311, 195, 5, 281, 273, 287, 291, 396, 428, 199, 311, 271, 268, 283, 444, 445,
  373, 254, 339, 263, 466, 249, 282, 334, 296, 449, 347, 346, 264, 447, 454, 336, 296, 299, 338, 10, 151, 278, 439, 455, 292, 407, 415, 358, 371, 355,
  340, 345, 372, 390, 249, 466, 346, 347, 280, 442, 443, 282, 19, 94, 370, 441, 442, 295, 248, 419, 197, 263, 255, 359, 440, 275, 274, 300, 383, 368,
  351, 412, 465, 263, 467, 466, 301, 368, 389, 380, 374, 386, 395, 378, 379, 412, 351, 419, 436, 426, 322, 373, 390, 388, 2, 164, 393, 370, 462, 461,
  164, 0, 267, 302, 11, 12, 374, 373, 387, 268, 12, 13, 293, 300, 301, 446, 261, 340, 385, 384, 381, 330, 266, 425, 426, 423, 391, 429, 355, 437, 391,
  327, 326, 440, 457, 438, 341, 382, 362, 459, 457, 461, 434, 430, 394, 414, 463, 362, 396, 369, 262, 354, 461, 457, 316, 403, 402, 315, 404, 403, 314,
  405, 404, 313, 406, 405, 421, 418, 406, 366, 401, 361, 306, 408, 407, 291, 409, 408, 287, 410, 409, 432, 436, 410, 434, 416, 411, 264, 368, 383, 309,
  438, 457, 352, 376, 401, 274, 275, 4, 421, 428, 262, 294, 327, 358, 433, 416, 367, 289, 455, 439, 462, 370, 326, 2, 326, 370, 305, 460, 455, 254,
  449, 448, 255, 261, 446, 253, 450, 449, 252, 451, 450, 256, 452, 451, 341, 453, 452, 413, 464, 463, 441, 413, 414, 258, 442, 441, 257, 443, 442, 259,
  444, 443, 260, 445, 444, 467, 342, 445, 459, 458, 250, 289, 392, 290, 290, 328, 460, 376, 433, 435, 250, 290, 392, 411, 416, 433, 341, 463, 464, 453,
  464, 465, 357, 465, 412, 343, 412, 399, 360, 363, 440, 437, 399, 456, 420, 456, 363, 401, 435, 288, 372, 383, 353, 339, 255, 249, 448, 261, 255, 133,
  243, 190, 133, 155, 112, 33, 246, 247, 33, 130, 25, 398, 384, 286, 362, 398, 414, 362, 463, 341, 263, 359, 467, 263, 249, 255, 466, 467, 260, 75, 60,
  166, 238, 239, 79, 162, 127, 139, 72, 11, 37, 121, 232, 120, 73, 72, 39, 114, 128, 47, 233, 232, 128, 103, 104, 67, 152, 175, 148, 173, 157, 155,
  119, 118, 101, 74, 73, 40, 107, 9, 108, 49, 48, 131, 32, 194, 211, 184, 74, 185, 191, 80, 183, 185, 40, 186, 119, 230, 118, 210, 202, 214, 84, 83, 17,
  77, 76, 146, 161, 160, 30, 190, 56, 173, 182, 106, 194, 138, 135, 192, 129, 203, 98, 54, 21, 68, 5, 51, 4, 145, 144, 23, 90, 77, 91, 207, 205, 187, 83,
  201, 18, 181, 91, 182, 180, 90, 181, 16, 85, 17, 205, 206, 36, 176, 148, 140, 165, 92, 39, 245, 193, 244, 27, 159, 28, 30, 247, 161, 174, 236, 196,
  103, 54, 104, 55, 193, 8, 111, 117, 31, 221, 189, 55, 240, 98, 99, 142, 126, 100, 219, 166, 218, 112, 155, 26, 198, 209, 131, 169, 135, 150, 114, 47,
  217, 224, 223, 53, 220, 45, 134, 32, 211, 140, 109, 67, 108, 146, 43, 91, 231, 230, 120, 113, 226, 247, 105, 63, 52, 241, 238, 242, 124, 46, 156, 95,
  78, 96, 70, 46, 63, 116, 143, 227, 116, 123, 111, 1, 44, 19, 3, 236, 51, 207, 216, 205, 26, 154, 22, 165, 39, 167, 199, 200, 208, 101, 36, 100, 43,
  57, 202, 242, 20, 99, 56, 28, 157, 124, 35, 113, 29, 160, 27, 211, 204, 210, 124, 113, 46, 106, 43, 204, 96, 62, 77, 227, 137, 116, 73, 41, 72, 36, 203,
  142, 235, 64, 240, 48, 49, 64, 42, 41, 74, 214, 212, 207, 183, 42, 184, 210, 169, 211, 140, 170, 176, 104, 105, 69, 193, 122, 168, 50, 123, 187, 89, 96,
  90, 66, 65, 107, 179, 89, 180, 119, 101, 120, 68, 63, 104, 234, 93, 227, 16, 15, 85, 209, 129, 49, 15, 14, 86, 107, 55, 9, 120, 100, 121, 153, 145, 22,
  178, 88, 179, 197, 6, 196, 89, 88, 96, 135, 138, 136, 138, 215, 172, 218, 115, 219, 41, 42, 81, 5, 195, 51, 57, 43, 61, 208, 171, 199, 41, 81, 38,
  224, 53, 225, 24, 144, 110, 105, 52, 66, 118, 229, 117, 227, 34, 234, 66, 107, 69, 10, 109, 151, 219, 48, 235, 183, 62, 191, 142, 129, 126, 116, 111,
  143, 7, 163, 246, 118, 117, 50, 223, 222, 52, 94, 19, 141, 222, 221, 65, 196, 3, 197, 45, 220, 44, 156, 70, 139, 188, 122, 245, 139, 71, 162, 145,
  153, 159, 149, 170, 150, 122, 188, 196, 206, 216, 92, 163, 144, 161, 164, 2, 167, 242, 141, 241, 0, 164, 37, 11, 72, 12, 144, 145, 160, 12, 38, 13, 70,
  63, 71, 31, 226, 111, 157, 158, 154, 36, 101, 205, 203, 206, 165, 126, 209, 217, 98, 165, 97, 237, 220, 218, 237, 239, 241, 210, 214, 169, 140, 171, 32,
  241, 125, 237, 179, 86, 178, 180, 85, 179, 181, 84, 180, 182, 83, 181, 194, 201, 182, 177, 137, 132, 184, 76, 183, 185, 61, 184, 186, 57, 185, 216, 212,
  186, 192, 214, 187, 139, 34, 156, 218, 79, 237, 147, 123, 177, 45, 44, 4, 208, 201, 32, 98, 64, 129, 192, 213, 138, 235, 59, 219, 141, 242, 97, 97, 2,
  141, 240, 75, 235, 229, 24, 228, 31, 25, 226, 230, 23, 229, 231, 22, 230, 232, 26, 231, 233, 112, 232, 244, 189, 243, 189, 221, 190, 222, 28, 221,
  223, 27, 222, 224, 29, 223, 225, 30, 224, 113, 247, 225, 99, 60, 240, 213, 147, 215, 60, 20, 166, 192, 187, 213, 243, 112, 244, 244, 233, 245, 245,
  128, 188, 188, 114, 174, 134, 131, 220, 174, 217, 236, 236, 198, 134, 215, 177, 58, 156, 143, 124, 25, 110, 7, 31, 228, 25, 264, 356, 368, 0, 11, 267,
  451, 452, 349, 267, 302, 269, 350, 357, 277, 350, 452, 357, 299, 333, 297, 396, 175, 377, 381, 384, 382, 280, 347, 330, 269, 303, 270, 151, 9, 337,
  344, 278, 360, 424, 418, 431, 270, 304, 409, 272, 310, 407, 322, 270, 410, 449, 450, 347, 432, 422, 434, 18, 313, 17, 291, 306, 375, 259, 387, 260,
  424, 335, 418, 434, 364, 416, 391, 423, 327, 301, 251, 298, 275, 281, 4, 254, 373, 253, 375, 307, 321, 280, 425, 411, 200, 421, 18, 335, 321, 406,
  321, 320, 405, 314, 315, 17, 423, 426, 266, 396, 377, 369, 270, 322, 269, 413, 417, 464, 385, 386, 258, 248, 456, 419, 298, 284, 333, 168, 417, 8,
  448, 346, 261, 417, 413, 285, 326, 327, 328, 277, 355, 329, 309, 392, 438, 381, 382, 256, 279, 429, 360, 365, 364, 379, 355, 277, 437, 282, 443, 283,
  281, 275, 363, 395, 431, 369, 299, 297, 337, 335, 273, 321, 348, 450, 349, 359, 446, 467, 283, 293, 282, 250, 458, 462, 300, 276, 383, 292, 308, 325,
  283, 276, 293, 264, 372, 447, 346, 352, 340, 354, 274, 19, 363, 456, 281, 426, 436, 425, 380, 381, 252, 267, 269, 393, 421, 200, 428, 371, 266, 329,
  432, 287, 422, 290, 250, 328, 385, 258, 384, 446, 265, 342, 386, 387, 257, 422, 424, 430, 445, 342, 276, 422, 273, 424, 306, 292, 307, 352, 366, 345,
  268, 271, 302, 358, 423, 371, 327, 294, 460, 331, 279, 294, 303, 271, 304, 436, 432, 427, 304, 272, 408, 395, 394, 431, 378, 395, 400, 296, 334, 299,
  6, 351, 168, 376, 352, 411, 307, 325, 320, 285, 295, 336, 320, 319, 404, 329, 330, 349, 334, 293, 333, 366, 323, 447, 316, 15, 315, 331, 358, 279,
  317, 14, 316, 8, 285, 9, 277, 329, 350, 253, 374, 252, 319, 318, 403, 351, 6, 419, 324, 318, 325, 397, 367, 365, 288, 435, 397, 278, 344, 439, 310,
  272, 311, 248, 195, 281, 375, 273, 291, 175, 396, 199, 312, 311, 268, 276, 283, 445, 390, 373, 339, 295, 282, 296, 448, 449, 346, 356, 264, 454, 337,
  336, 299, 337, 338, 151, 294, 278, 455, 308, 292, 415, 429, 358, 355, 265, 340, 372, 388, 390, 466, 352, 346, 280, 295, 442, 282, 354, 19, 370, 285,
  441, 295, 195, 248, 197, 457, 440, 274, 301, 300, 368, 417, 351, 465, 251, 301, 389, 385, 380, 386, 394, 395, 379, 399, 412, 419, 410, 436, 322, 387,
  373, 388, 326, 2, 393, 354, 370, 461, 393, 164, 267, 268, 302, 12, 386, 374, 387, 312, 268, 13, 298, 293, 301, 265, 446, 340, 380, 385, 381, 280, 330,
  425, 322, 426, 391, 420, 429, 437, 393, 391, 326, 344, 440, 438, 458, 459, 461, 364, 434, 394, 428, 396, 262, 274, 354, 457, 317, 316, 402, 316, 315,
  403, 315, 314, 404, 314, 313, 405, 313, 421, 406, 323, 366, 361, 292, 306, 407, 306, 291, 408, 291, 287, 409, 287, 432, 410, 427, 434, 411, 372, 264,
  383, 459, 309, 457, 366, 352, 401, 1, 274, 4, 418, 421, 262, 331, 294, 358, 435, 433, 367, 392, 289, 439, 328, 462, 326, 94, 2, 370, 289, 305, 455, 339,
  254, 448, 359, 255, 446, 254, 253, 449, 253, 252, 450, 252, 256, 451, 256, 341, 452, 414, 413, 463, 286, 441, 414, 286, 258, 441, 258, 257, 442, 257,
  259, 443, 259, 260, 444, 260, 467, 445, 309, 459, 250, 305, 289, 290, 305, 290, 460, 401, 376, 435, 309, 250, 392, 376, 411, 433, 453, 341, 464, 357,
  453, 465, 343, 357, 412, 437, 343, 399, 344, 360, 440, 420, 437, 456, 360, 420, 363, 361, 401, 288, 265, 372, 353, 390, 339, 249, 339, 448, 255];

export const TRI68: number[] = [0, 1, 36, 0, 36, 17, 1, 2, 41, 1, 41, 36, 2, 3, 31, 2, 31, 41, 3, 4, 48, 3, 48, 31, 4, 5, 48, 5, 6, 48, 6, 7, 59, 6, 59, 48, 7, 8, 58, 7, 58, 59,
  8, 9, 56, 8, 56, 57, 8, 57, 58, 9, 10, 55, 9, 55, 56, 10, 11, 54, 10, 54, 55, 11, 12, 54, 12, 13, 54, 13, 14, 35, 13, 35, 54, 14, 15, 46, 14, 46, 35, 15, 16,
  45, 15, 45, 46, 16, 26, 45, 17, 36, 18, 18, 37, 19, 18, 36, 37, 19, 38, 20, 19, 37, 38, 20, 39, 21, 20, 38, 39, 21, 39, 27, 22, 42, 23, 22, 27, 42, 23, 43, 24,
  23, 42, 43, 24, 44, 25, 24, 43, 44, 25, 45, 26, 25, 44, 45, 27, 39, 28, 27, 28, 42, 28, 39, 29, 28, 29, 42, 29, 31, 30, 29, 30, 35, 29, 40, 31, 29, 35, 47, 29,
  39, 40, 29, 47, 42, 30, 31, 32, 30, 32, 33, 30, 33, 34, 30, 34, 35, 31, 50, 32, 31, 40, 41, 31, 48, 49, 31, 49, 50, 32, 51, 33, 32, 50, 51, 33, 51, 34, 34, 52,
  35, 34, 51, 52, 35, 46, 47, 35, 52, 53, 35, 53, 54, 36, 41, 37, 37, 40, 38, 37, 41, 40, 38, 40, 39, 42, 47, 43, 43, 47, 44, 44, 46, 45, 44, 47, 46, 48, 60, 49,
  48, 59, 60, 49, 61, 50, 49, 60, 61, 50, 62, 51, 50, 61, 62, 51, 62, 52, 52, 63, 53, 52, 62, 63, 53, 64, 54, 53, 63, 64, 54, 64, 55, 55, 65, 56, 55, 64, 65, 56,
  66, 57, 56, 65, 66, 57, 66, 58, 58, 67, 59, 58, 66, 67, 59, 67, 60, 60, 67, 61, 61, 66, 62, 61, 67, 66, 62, 66, 63, 63, 65, 64, 63, 66, 65, 21, 27, 22];

export const TRI33: number[] = [
  /*  eyes  */ 0, 8, 7, 7, 8, 1, 2, 10, 9, 9, 10, 3,
  /*  brows */ 17, 0, 18, 18, 0, 7, 18, 7, 19, 19, 7, 1, 19, 1, 11, 19, 11, 20, 21, 3, 22, 21, 9, 3, 20, 9, 21, 20, 2, 9, 20, 11, 2,
  /*  4head */ 23, 17, 18, 25, 21, 22, 24, 19, 20, 24, 18, 19, 24, 20, 21, 24, 23, 18, 24, 21, 25,
  /*  nose  */ 11, 12, 4, 11, 4, 13, 1, 12, 11, 11, 13, 2, 12, 14, 4, 4, 14, 13,
  /* up-lip */ 14, 5, 15, 14, 15, 6, 12, 5, 14, 14, 6, 13,
  /* cheeks */ 8, 12, 1, 2, 13, 10, 8, 26, 12, 10, 13, 27, 26, 5, 12, 13, 6, 27, 0, 26, 8, 10, 27, 3,
  /*  chin  */ 5, 32, 16, 16, 32, 6, 5, 30, 32, 6, 32, 31,
  /*  cont  */ 26, 30, 5, 27, 6, 31, 0, 28, 26, 3, 27, 29, 17, 28, 0, 3, 29, 22, 23, 28, 17, 22, 29, 25, 28, 30, 26, 27, 31, 29,
];

export const TRI7: number[] = [0, 4, 1, 2, 4, 3, 4, 5, 6];

export const VTX68: number[] = [
  /* cont  */ 127, 234, 132, 58, 172, 150, 149, 148, 152, 377, 378, 379, 397, 288, 361, 454, 356,
  /* brows */ 70, 63, 105, 66, 107, 336, 296, 334, 293, 300,
  /* nose  */ 168, 6, 195, 4, 98, 97, 2, 326, 327,
  /* eyes  */ 33, 160, 158, 133, 153, 144, 362, 385, 387, 263, 373, 380,
  /* lip   */ 57, 40, 37, 0, 267, 270, 287, 321, 314, 17, 84, 91,
  /* mouth */ 78, 81, 13, 311, 308, 402, 14, 178,
];

export const VTX33: number[] = [33, 133, 362, 263, 1, 62, 308, 159, 145, 386, 374, 6, 102, 331, 2, 13, 14, 70, 105, 107, 336, 334, 300, 54, 10, 284, 50, 280, 234, 454, 58, 288, 152];

export const VTX7: number[] = [33, 133, 362, 263, 1, 78, 308];

export const UV68 = VTX68.map((x) => UV468[x]);

export const UV33 = VTX33.map((x) => UV468[x]);

export const UV7 = VTX7.map((x) => UV468[x]);

// https://github.com/tensorflow/tfjs-models/blob/master/face-landmarks-detection/src/constants.ts
// https://github.com/google/mediapipe/mediapipe/python/solutions/face_mesh_connections.py

type PairArray = [number, number][];

function connectionsToIndices(connections: PairArray) {
  const indices = connections.map((connection) => connection[0]);
  indices.push(connections[connections.length - 1][1]);
  return indices;
}

export const pairsLips: PairArray = [
  [61, 146], [146, 91], [91, 181], [181, 84], [84, 17], [17, 314], [314, 405], [405, 321], [321, 375], [375, 291], [61, 185], [185, 40], [40, 39], [39, 37], [37, 0], [0, 267], [267, 269], [269, 270], [270, 409], [409, 291],
  [78, 95], [95, 88], [88, 178], [178, 87], [87, 14], [14, 317], [317, 402], [402, 318], [318, 324], [324, 308], [78, 191], [191, 80], [80, 81], [81, 82], [82, 13], [13, 312], [312, 311], [311, 310], [310, 415], [415, 308],
];

export const pairsLeftEye: PairArray = [[263, 249], [249, 390], [390, 373], [373, 374], [374, 380], [380, 381], [381, 382], [382, 362], [263, 466], [466, 388], [388, 387], [387, 386], [386, 385], [385, 384], [384, 398], [398, 362]];

export const pairsLeftEyebrow: PairArray = [[276, 283], [283, 282], [282, 295], [295, 285], [300, 293], [293, 334], [334, 296], [296, 336]];

export const pairsLeftIris: PairArray = [[474, 475], [475, 476], [476, 477], [477, 474]];

export const pairsRightEye: PairArray = [[33, 7], [7, 163], [163, 144], [144, 145], [145, 153], [153, 154], [154, 155], [155, 133], [33, 246], [246, 161], [161, 160], [160, 159], [159, 158], [158, 157], [157, 173], [173, 133]];

export const pairsRightEyebrow: PairArray = [[46, 53], [53, 52], [52, 65], [65, 55], [70, 63], [63, 105], [105, 66], [66, 107]];

export const pairsRightIris: PairArray = [[469, 470], [470, 471], [471, 472], [472, 469]];

export const pairsFaceContour: PairArray = [
  [10, 338], [338, 297], [297, 332], [332, 284], [284, 251], [251, 389],
  [389, 356], [356, 454], [454, 323], [323, 361], [361, 288], [288, 397],
  [397, 365], [365, 379], [379, 378], [378, 400], [400, 377], [377, 152],
  [152, 148], [148, 176], [176, 149], [149, 150], [150, 136], [136, 172],
  [172, 58], [58, 132], [132, 93], [93, 234], [234, 127], [127, 162],
  [162, 21], [21, 54], [54, 103], [103, 67], [67, 109], [109, 10],
];

export const contourKeypoints = {
  lips: connectionsToIndices(pairsLips),
  leftEye: connectionsToIndices(pairsLeftEye),
  leftEyebrow: connectionsToIndices(pairsLeftEyebrow),
  leftIris: connectionsToIndices(pairsLeftIris),
  rightEye: connectionsToIndices(pairsRightEye),
  rightEyebrow: connectionsToIndices(pairsRightEyebrow),
  rightIris: connectionsToIndices(pairsRightIris),
  faceOval: connectionsToIndices(pairsFaceContour),
};

export const pairsFaceMesh: PairArray = [
  [127, 34], [34, 139], [139, 127], [11, 0], [0, 37], [37, 11],
  [232, 231], [231, 120], [120, 232], [72, 37], [37, 39], [39, 72],
  [128, 121], [121, 47], [47, 128], [232, 121], [121, 128], [128, 232],
  [104, 69], [69, 67], [67, 104], [175, 171], [171, 148], [148, 175],
  [118, 50], [50, 101], [101, 118], [73, 39], [39, 40], [40, 73],
  [9, 151], [151, 108], [108, 9], [48, 115], [115, 131], [131, 48],
  [194, 204], [204, 211], [211, 194], [74, 40], [40, 185], [185, 74],
  [80, 42], [42, 183], [183, 80], [40, 92], [92, 186], [186, 40],
  [230, 229], [229, 118], [118, 230], [202, 212], [212, 214], [214, 202],
  [83, 18], [18, 17], [17, 83], [76, 61], [61, 146], [146, 76],
  [160, 29], [29, 30], [30, 160], [56, 157], [157, 173], [173, 56],
  [106, 204], [204, 194], [194, 106], [135, 214], [214, 192], [192, 135],
  [203, 165], [165, 98], [98, 203], [21, 71], [71, 68], [68, 21],
  [51, 45], [45, 4], [4, 51], [144, 24], [24, 23], [23, 144],
  [77, 146], [146, 91], [91, 77], [205, 50], [50, 187], [187, 205],
  [201, 200], [200, 18], [18, 201], [91, 106], [106, 182], [182, 91],
  [90, 91], [91, 181], [181, 90], [85, 84], [84, 17], [17, 85],
  [206, 203], [203, 36], [36, 206], [148, 171], [171, 140], [140, 148],
  [92, 40], [40, 39], [39, 92], [193, 189], [189, 244], [244, 193],
  [159, 158], [158, 28], [28, 159], [247, 246], [246, 161], [161, 247],
  [236, 3], [3, 196], [196, 236], [54, 68], [68, 104], [104, 54],
  [193, 168], [168, 8], [8, 193], [117, 228], [228, 31], [31, 117],
  [189, 193], [193, 55], [55, 189], [98, 97], [97, 99], [99, 98],
  [126, 47], [47, 100], [100, 126], [166, 79], [79, 218], [218, 166],
  [155, 154], [154, 26], [26, 155], [209, 49], [49, 131], [131, 209],
  [135, 136], [136, 150], [150, 135], [47, 126], [126, 217], [217, 47],
  [223, 52], [52, 53], [53, 223], [45, 51], [51, 134], [134, 45],
  [211, 170], [170, 140], [140, 211], [67, 69], [69, 108], [108, 67],
  [43, 106], [106, 91], [91, 43], [230, 119], [119, 120], [120, 230],
  [226, 130], [130, 247], [247, 226], [63, 53], [53, 52], [52, 63],
  [238, 20], [20, 242], [242, 238], [46, 70], [70, 156], [156, 46],
  [78, 62], [62, 96], [96, 78], [46, 53], [53, 63], [63, 46],
  [143, 34], [34, 227], [227, 143], [123, 117], [117, 111], [111, 123],
  [44, 125], [125, 19], [19, 44], [236, 134], [134, 51], [51, 236],
  [216, 206], [206, 205], [205, 216], [154, 153], [153, 22], [22, 154],
  [39, 37], [37, 167], [167, 39], [200, 201], [201, 208], [208, 200],
  [36, 142], [142, 100], [100, 36], [57, 212], [212, 202], [202, 57],
  [20, 60], [60, 99], [99, 20], [28, 158], [158, 157], [157, 28],
  [35, 226], [226, 113], [113, 35], [160, 159], [159, 27], [27, 160],
  [204, 202], [202, 210], [210, 204], [113, 225], [225, 46], [46, 113],
  [43, 202], [202, 204], [204, 43], [62, 76], [76, 77], [77, 62],
  [137, 123], [123, 116], [116, 137], [41, 38], [38, 72], [72, 41],
  [203, 129], [129, 142], [142, 203], [64, 98], [98, 240], [240, 64],
  [49, 102], [102, 64], [64, 49], [41, 73], [73, 74], [74, 41],
  [212, 216], [216, 207], [207, 212], [42, 74], [74, 184], [184, 42],
  [169, 170], [170, 211], [211, 169], [170, 149], [149, 176], [176, 170],
  [105, 66], [66, 69], [69, 105], [122, 6], [6, 168], [168, 122],
  [123, 147], [147, 187], [187, 123], [96, 77], [77, 90], [90, 96],
  [65, 55], [55, 107], [107, 65], [89, 90], [90, 180], [180, 89],
  [101, 100], [100, 120], [120, 101], [63, 105], [105, 104], [104, 63],
  [93, 137], [137, 227], [227, 93], [15, 86], [86, 85], [85, 15],
  [129, 102], [102, 49], [49, 129], [14, 87], [87, 86], [86, 14],
  [55, 8], [8, 9], [9, 55], [100, 47], [47, 121], [121, 100],
  [145, 23], [23, 22], [22, 145], [88, 89], [89, 179], [179, 88],
  [6, 122], [122, 196], [196, 6], [88, 95], [95, 96], [96, 88],
  [138, 172], [172, 136], [136, 138], [215, 58], [58, 172], [172, 215],
  [115, 48], [48, 219], [219, 115], [42, 80], [80, 81], [81, 42],
  [195, 3], [3, 51], [51, 195], [43, 146], [146, 61], [61, 43],
  [171, 175], [175, 199], [199, 171], [81, 82], [82, 38], [38, 81],
  [53, 46], [46, 225], [225, 53], [144, 163], [163, 110], [110, 144],
  [52, 65], [65, 66], [66, 52], [229, 228], [228, 117], [117, 229],
  [34, 127], [127, 234], [234, 34], [107, 108], [108, 69], [69, 107],
  [109, 108], [108, 151], [151, 109], [48, 64], [64, 235], [235, 48],
  [62, 78], [78, 191], [191, 62], [129, 209], [209, 126], [126, 129],
  [111, 35], [35, 143], [143, 111], [117, 123], [123, 50], [50, 117],
  [222, 65], [65, 52], [52, 222], [19, 125], [125, 141], [141, 19],
  [221, 55], [55, 65], [65, 221], [3, 195], [195, 197], [197, 3],
  [25, 7], [7, 33], [33, 25], [220, 237], [237, 44], [44, 220],
  [70, 71], [71, 139], [139, 70], [122, 193], [193, 245], [245, 122],
  [247, 130], [130, 33], [33, 247], [71, 21], [21, 162], [162, 71],
  [170, 169], [169, 150], [150, 170], [188, 174], [174, 196], [196, 188],
  [216, 186], [186, 92], [92, 216], [2, 97], [97, 167], [167, 2],
  [141, 125], [125, 241], [241, 141], [164, 167], [167, 37], [37, 164],
  [72, 38], [38, 12], [12, 72], [38, 82], [82, 13], [13, 38],
  [63, 68], [68, 71], [71, 63], [226, 35], [35, 111], [111, 226],
  [101, 50], [50, 205], [205, 101], [206, 92], [92, 165], [165, 206],
  [209, 198], [198, 217], [217, 209], [165, 167], [167, 97], [97, 165],
  [220, 115], [115, 218], [218, 220], [133, 112], [112, 243], [243, 133],
  [239, 238], [238, 241], [241, 239], [214, 135], [135, 169], [169, 214],
  [190, 173], [173, 133], [133, 190], [171, 208], [208, 32], [32, 171],
  [125, 44], [44, 237], [237, 125], [86, 87], [87, 178], [178, 86],
  [85, 86], [86, 179], [179, 85], [84, 85], [85, 180], [180, 84],
  [83, 84], [84, 181], [181, 83], [201, 83], [83, 182], [182, 201],
  [137, 93], [93, 132], [132, 137], [76, 62], [62, 183], [183, 76],
  [61, 76], [76, 184], [184, 61], [57, 61], [61, 185], [185, 57],
  [212, 57], [57, 186], [186, 212], [214, 207], [207, 187], [187, 214],
  [34, 143], [143, 156], [156, 34], [79, 239], [239, 237], [237, 79],
  [123, 137], [137, 177], [177, 123], [44, 1], [1, 4], [4, 44],
  [201, 194], [194, 32], [32, 201], [64, 102], [102, 129], [129, 64],
  [213, 215], [215, 138], [138, 213], [59, 166], [166, 219], [219, 59],
  [242, 99], [99, 97], [97, 242], [2, 94], [94, 141], [141, 2],
  [75, 59], [59, 235], [235, 75], [24, 110], [110, 228], [228, 24],
  [25, 130], [130, 226], [226, 25], [23, 24], [24, 229], [229, 23],
  [22, 23], [23, 230], [230, 22], [26, 22], [22, 231], [231, 26],
  [112, 26], [26, 232], [232, 112], [189, 190], [190, 243], [243, 189],
  [221, 56], [56, 190], [190, 221], [28, 56], [56, 221], [221, 28],
  [27, 28], [28, 222], [222, 27], [29, 27], [27, 223], [223, 29],
  [30, 29], [29, 224], [224, 30], [247, 30], [30, 225], [225, 247],
  [238, 79], [79, 20], [20, 238], [166, 59], [59, 75], [75, 166],
  [60, 75], [75, 240], [240, 60], [147, 177], [177, 215], [215, 147],
  [20, 79], [79, 166], [166, 20], [187, 147], [147, 213], [213, 187],
  [112, 233], [233, 244], [244, 112], [233, 128], [128, 245], [245, 233],
  [128, 114], [114, 188], [188, 128], [114, 217], [217, 174], [174, 114],
  [131, 115], [115, 220], [220, 131], [217, 198], [198, 236], [236, 217],
  [198, 131], [131, 134], [134, 198], [177, 132], [132, 58], [58, 177],
  [143, 35], [35, 124], [124, 143], [110, 163], [163, 7], [7, 110],
  [228, 110], [110, 25], [25, 228], [356, 389], [389, 368], [368, 356],
  [11, 302], [302, 267], [267, 11], [452, 350], [350, 349], [349, 452],
  [302, 303], [303, 269], [269, 302], [357, 343], [343, 277], [277, 357],
  [452, 453], [453, 357], [357, 452], [333, 332], [332, 297], [297, 333],
  [175, 152], [152, 377], [377, 175], [347, 348], [348, 330], [330, 347],
  [303, 304], [304, 270], [270, 303], [9, 336], [336, 337], [337, 9],
  [278, 279], [279, 360], [360, 278], [418, 262], [262, 431], [431, 418],
  [304, 408], [408, 409], [409, 304], [310, 415], [415, 407], [407, 310],
  [270, 409], [409, 410], [410, 270], [450, 348], [348, 347], [347, 450],
  [422, 430], [430, 434], [434, 422], [313, 314], [314, 17], [17, 313],
  [306, 307], [307, 375], [375, 306], [387, 388], [388, 260], [260, 387],
  [286, 414], [414, 398], [398, 286], [335, 406], [406, 418], [418, 335],
  [364, 367], [367, 416], [416, 364], [423, 358], [358, 327], [327, 423],
  [251, 284], [284, 298], [298, 251], [281, 5], [5, 4], [4, 281],
  [373, 374], [374, 253], [253, 373], [307, 320], [320, 321], [321, 307],
  [425, 427], [427, 411], [411, 425], [421, 313], [313, 18], [18, 421],
  [321, 405], [405, 406], [406, 321], [320, 404], [404, 405], [405, 320],
  [315, 16], [16, 17], [17, 315], [426, 425], [425, 266], [266, 426],
  [377, 400], [400, 369], [369, 377], [322, 391], [391, 269], [269, 322],
  [417, 465], [465, 464], [464, 417], [386, 257], [257, 258], [258, 386],
  [466, 260], [260, 388], [388, 466], [456, 399], [399, 419], [419, 456],
  [284, 332], [332, 333], [333, 284], [417, 285], [285, 8], [8, 417],
  [346, 340], [340, 261], [261, 346], [413, 441], [441, 285], [285, 413],
  [327, 460], [460, 328], [328, 327], [355, 371], [371, 329], [329, 355],
  [392, 439], [439, 438], [438, 392], [382, 341], [341, 256], [256, 382],
  [429, 420], [420, 360], [360, 429], [364, 394], [394, 379], [379, 364],
  [277, 343], [343, 437], [437, 277], [443, 444], [444, 283], [283, 443],
  [275, 440], [440, 363], [363, 275], [431, 262], [262, 369], [369, 431],
  [297, 338], [338, 337], [337, 297], [273, 375], [375, 321], [321, 273],
  [450, 451], [451, 349], [349, 450], [446, 342], [342, 467], [467, 446],
  [293, 334], [334, 282], [282, 293], [458, 461], [461, 462], [462, 458],
  [276, 353], [353, 383], [383, 276], [308, 324], [324, 325], [325, 308],
  [276, 300], [300, 293], [293, 276], [372, 345], [345, 447], [447, 372],
  [352, 345], [345, 340], [340, 352], [274, 1], [1, 19], [19, 274],
  [456, 248], [248, 281], [281, 456], [436, 427], [427, 425], [425, 436],
  [381, 256], [256, 252], [252, 381], [269, 391], [391, 393], [393, 269],
  [200, 199], [199, 428], [428, 200], [266, 330], [330, 329], [329, 266],
  [287, 273], [273, 422], [422, 287], [250, 462], [462, 328], [328, 250],
  [258, 286], [286, 384], [384, 258], [265, 353], [353, 342], [342, 265],
  [387, 259], [259, 257], [257, 387], [424, 431], [431, 430], [430, 424],
  [342, 353], [353, 276], [276, 342], [273, 335], [335, 424], [424, 273],
  [292, 325], [325, 307], [307, 292], [366, 447], [447, 345], [345, 366],
  [271, 303], [303, 302], [302, 271], [423, 266], [266, 371], [371, 423],
  [294, 455], [455, 460], [460, 294], [279, 278], [278, 294], [294, 279],
  [271, 272], [272, 304], [304, 271], [432, 434], [434, 427], [427, 432],
  [272, 407], [407, 408], [408, 272], [394, 430], [430, 431], [431, 394],
  [395, 369], [369, 400], [400, 395], [334, 333], [333, 299], [299, 334],
  [351, 417], [417, 168], [168, 351], [352, 280], [280, 411], [411, 352],
  [325, 319], [319, 320], [320, 325], [295, 296], [296, 336], [336, 295],
  [319, 403], [403, 404], [404, 319], [330, 348], [348, 349], [349, 330],
  [293, 298], [298, 333], [333, 293], [323, 454], [454, 447], [447, 323],
  [15, 16], [16, 315], [315, 15], [358, 429], [429, 279], [279, 358],
  [14, 15], [15, 316], [316, 14], [285, 336], [336, 9], [9, 285],
  [329, 349], [349, 350], [350, 329], [374, 380], [380, 252], [252, 374],
  [318, 402], [402, 403], [403, 318], [6, 197], [197, 419], [419, 6],
  [318, 319], [319, 325], [325, 318], [367, 364], [364, 365], [365, 367],
  [435, 367], [367, 397], [397, 435], [344, 438], [438, 439], [439, 344],
  [272, 271], [271, 311], [311, 272], [195, 5], [5, 281], [281, 195],
  [273, 287], [287, 291], [291, 273], [396, 428], [428, 199], [199, 396],
  [311, 271], [271, 268], [268, 311], [283, 444], [444, 445], [445, 283],
  [373, 254], [254, 339], [339, 373], [282, 334], [334, 296], [296, 282],
  [449, 347], [347, 346], [346, 449], [264, 447], [447, 454], [454, 264],
  [336, 296], [296, 299], [299, 336], [338, 10], [10, 151], [151, 338],
  [278, 439], [439, 455], [455, 278], [292, 407], [407, 415], [415, 292],
  [358, 371], [371, 355], [355, 358], [340, 345], [345, 372], [372, 340],
  [346, 347], [347, 280], [280, 346], [442, 443], [443, 282], [282, 442],
  [19, 94], [94, 370], [370, 19], [441, 442], [442, 295], [295, 441],
  [248, 419], [419, 197], [197, 248], [263, 255], [255, 359], [359, 263],
  [440, 275], [275, 274], [274, 440], [300, 383], [383, 368], [368, 300],
  [351, 412], [412, 465], [465, 351], [263, 467], [467, 466], [466, 263],
  [301, 368], [368, 389], [389, 301], [395, 378], [378, 379], [379, 395],
  [412, 351], [351, 419], [419, 412], [436, 426], [426, 322], [322, 436],
  [2, 164], [164, 393], [393, 2], [370, 462], [462, 461], [461, 370],
  [164, 0], [0, 267], [267, 164], [302, 11], [11, 12], [12, 302],
  [268, 12], [12, 13], [13, 268], [293, 300], [300, 301], [301, 293],
  [446, 261], [261, 340], [340, 446], [330, 266], [266, 425], [425, 330],
  [426, 423], [423, 391], [391, 426], [429, 355], [355, 437], [437, 429],
  [391, 327], [327, 326], [326, 391], [440, 457], [457, 438], [438, 440],
  [341, 382], [382, 362], [362, 341], [459, 457], [457, 461], [461, 459],
  [434, 430], [430, 394], [394, 434], [414, 463], [463, 362], [362, 414],
  [396, 369], [369, 262], [262, 396], [354, 461], [461, 457], [457, 354],
  [316, 403], [403, 402], [402, 316], [315, 404], [404, 403], [403, 315],
  [314, 405], [405, 404], [404, 314], [313, 406], [406, 405], [405, 313],
  [421, 418], [418, 406], [406, 421], [366, 401], [401, 361], [361, 366],
  [306, 408], [408, 407], [407, 306], [291, 409], [409, 408], [408, 291],
  [287, 410], [410, 409], [409, 287], [432, 436], [436, 410], [410, 432],
  [434, 416], [416, 411], [411, 434], [264, 368], [368, 383], [383, 264],
  [309, 438], [438, 457], [457, 309], [352, 376], [376, 401], [401, 352],
  [274, 275], [275, 4], [4, 274], [421, 428], [428, 262], [262, 421],
  [294, 327], [327, 358], [358, 294], [433, 416], [416, 367], [367, 433],
  [289, 455], [455, 439], [439, 289], [462, 370], [370, 326], [326, 462],
  [2, 326], [326, 370], [370, 2], [305, 460], [460, 455], [455, 305],
  [254, 449], [449, 448], [448, 254], [255, 261], [261, 446], [446, 255],
  [253, 450], [450, 449], [449, 253], [252, 451], [451, 450], [450, 252],
  [256, 452], [452, 451], [451, 256], [341, 453], [453, 452], [452, 341],
  [413, 464], [464, 463], [463, 413], [441, 413], [413, 414], [414, 441],
  [258, 442], [442, 441], [441, 258], [257, 443], [443, 442], [442, 257],
  [259, 444], [444, 443], [443, 259], [260, 445], [445, 444], [444, 260],
  [467, 342], [342, 445], [445, 467], [459, 458], [458, 250], [250, 459],
  [289, 392], [392, 290], [290, 289], [290, 328], [328, 460], [460, 290],
  [376, 433], [433, 435], [435, 376], [250, 290], [290, 392], [392, 250],
  [411, 416], [416, 433], [433, 411], [341, 463], [463, 464], [464, 341],
  [453, 464], [464, 465], [465, 453], [357, 465], [465, 412], [412, 357],
  [343, 412], [412, 399], [399, 343], [360, 363], [363, 440], [440, 360],
  [437, 399], [399, 456], [456, 437], [420, 456], [456, 363], [363, 420],
  [401, 435], [435, 288], [288, 401], [372, 383], [383, 353], [353, 372],
  [339, 255], [255, 249], [249, 339], [448, 261], [261, 255], [255, 448],
  [133, 243], [243, 190], [190, 133], [133, 155], [155, 112], [112, 133],
  [33, 246], [246, 247], [247, 33], [33, 130], [130, 25], [25, 33],
  [398, 384], [384, 286], [286, 398], [362, 398], [398, 414], [414, 362],
  [362, 463], [463, 341], [341, 362], [263, 359], [359, 467], [467, 263],
  [263, 249], [249, 255], [255, 263], [466, 467], [467, 260], [260, 466],
  [75, 60], [60, 166], [166, 75], [238, 239], [239, 79], [79, 238],
  [162, 127], [127, 139], [139, 162], [72, 11], [11, 37], [37, 72],
  [121, 232], [232, 120], [120, 121], [73, 72], [72, 39], [39, 73],
  [114, 128], [128, 47], [47, 114], [233, 232], [232, 128], [128, 233],
  [103, 104], [104, 67], [67, 103], [152, 175], [175, 148], [148, 152],
  [119, 118], [118, 101], [101, 119], [74, 73], [73, 40], [40, 74],
  [107, 9], [9, 108], [108, 107], [49, 48], [48, 131], [131, 49],
  [32, 194], [194, 211], [211, 32], [184, 74], [74, 185], [185, 184],
  [191, 80], [80, 183], [183, 191], [185, 40], [40, 186], [186, 185],
  [119, 230], [230, 118], [118, 119], [210, 202], [202, 214], [214, 210],
  [84, 83], [83, 17], [17, 84], [77, 76], [76, 146], [146, 77],
  [161, 160], [160, 30], [30, 161], [190, 56], [56, 173], [173, 190],
  [182, 106], [106, 194], [194, 182], [138, 135], [135, 192], [192, 138],
  [129, 203], [203, 98], [98, 129], [54, 21], [21, 68], [68, 54],
  [5, 51], [51, 4], [4, 5], [145, 144], [144, 23], [23, 145],
  [90, 77], [77, 91], [91, 90], [207, 205], [205, 187], [187, 207],
  [83, 201], [201, 18], [18, 83], [181, 91], [91, 182], [182, 181],
  [180, 90], [90, 181], [181, 180], [16, 85], [85, 17], [17, 16],
  [205, 206], [206, 36], [36, 205], [176, 148], [148, 140], [140, 176],
  [165, 92], [92, 39], [39, 165], [245, 193], [193, 244], [244, 245],
  [27, 159], [159, 28], [28, 27], [30, 247], [247, 161], [161, 30],
  [174, 236], [236, 196], [196, 174], [103, 54], [54, 104], [104, 103],
  [55, 193], [193, 8], [8, 55], [111, 117], [117, 31], [31, 111],
  [221, 189], [189, 55], [55, 221], [240, 98], [98, 99], [99, 240],
  [142, 126], [126, 100], [100, 142], [219, 166], [166, 218], [218, 219],
  [112, 155], [155, 26], [26, 112], [198, 209], [209, 131], [131, 198],
  [169, 135], [135, 150], [150, 169], [114, 47], [47, 217], [217, 114],
  [224, 223], [223, 53], [53, 224], [220, 45], [45, 134], [134, 220],
  [32, 211], [211, 140], [140, 32], [109, 67], [67, 108], [108, 109],
  [146, 43], [43, 91], [91, 146], [231, 230], [230, 120], [120, 231],
  [113, 226], [226, 247], [247, 113], [105, 63], [63, 52], [52, 105],
  [241, 238], [238, 242], [242, 241], [124, 46], [46, 156], [156, 124],
  [95, 78], [78, 96], [96, 95], [70, 46], [46, 63], [63, 70],
  [116, 143], [143, 227], [227, 116], [116, 123], [123, 111], [111, 116],
  [1, 44], [44, 19], [19, 1], [3, 236], [236, 51], [51, 3],
  [207, 216], [216, 205], [205, 207], [26, 154], [154, 22], [22, 26],
  [165, 39], [39, 167], [167, 165], [199, 200], [200, 208], [208, 199],
  [101, 36], [36, 100], [100, 101], [43, 57], [57, 202], [202, 43],
  [242, 20], [20, 99], [99, 242], [56, 28], [28, 157], [157, 56],
  [124, 35], [35, 113], [113, 124], [29, 160], [160, 27], [27, 29],
  [211, 204], [204, 210], [210, 211], [124, 113], [113, 46], [46, 124],
  [106, 43], [43, 204], [204, 106], [96, 62], [62, 77], [77, 96],
  [227, 137], [137, 116], [116, 227], [73, 41], [41, 72], [72, 73],
  [36, 203], [203, 142], [142, 36], [235, 64], [64, 240], [240, 235],
  [48, 49], [49, 64], [64, 48], [42, 41], [41, 74], [74, 42],
  [214, 212], [212, 207], [207, 214], [183, 42], [42, 184], [184, 183],
  [210, 169], [169, 211], [211, 210], [140, 170], [170, 176], [176, 140],
  [104, 105], [105, 69], [69, 104], [193, 122], [122, 168], [168, 193],
  [50, 123], [123, 187], [187, 50], [89, 96], [96, 90], [90, 89],
  [66, 65], [65, 107], [107, 66], [179, 89], [89, 180], [180, 179],
  [119, 101], [101, 120], [120, 119], [68, 63], [63, 104], [104, 68],
  [234, 93], [93, 227], [227, 234], [16, 15], [15, 85], [85, 16],
  [209, 129], [129, 49], [49, 209], [15, 14], [14, 86], [86, 15],
  [107, 55], [55, 9], [9, 107], [120, 100], [100, 121], [121, 120],
  [153, 145], [145, 22], [22, 153], [178, 88], [88, 179], [179, 178],
  [197, 6], [6, 196], [196, 197], [89, 88], [88, 96], [96, 89],
  [135, 138], [138, 136], [136, 135], [138, 215], [215, 172], [172, 138],
  [218, 115], [115, 219], [219, 218], [41, 42], [42, 81], [81, 41],
  [5, 195], [195, 51], [51, 5], [57, 43], [43, 61], [61, 57],
  [208, 171], [171, 199], [199, 208], [41, 81], [81, 38], [38, 41],
  [224, 53], [53, 225], [225, 224], [24, 144], [144, 110], [110, 24],
  [105, 52], [52, 66], [66, 105], [118, 229], [229, 117], [117, 118],
  [227, 34], [34, 234], [234, 227], [66, 107], [107, 69], [69, 66],
  [10, 109], [109, 151], [151, 10], [219, 48], [48, 235], [235, 219],
  [183, 62], [62, 191], [191, 183], [142, 129], [129, 126], [126, 142],
  [116, 111], [111, 143], [143, 116], [118, 117], [117, 50], [50, 118],
  [223, 222], [222, 52], [52, 223], [94, 19], [19, 141], [141, 94],
  [222, 221], [221, 65], [65, 222], [196, 3], [3, 197], [197, 196],
  [45, 220], [220, 44], [44, 45], [156, 70], [70, 139], [139, 156],
  [188, 122], [122, 245], [245, 188], [139, 71], [71, 162], [162, 139],
  [149, 170], [170, 150], [150, 149], [122, 188], [188, 196], [196, 122],
  [206, 216], [216, 92], [92, 206], [164, 2], [2, 167], [167, 164],
  [242, 141], [141, 241], [241, 242], [0, 164], [164, 37], [37, 0],
  [11, 72], [72, 12], [12, 11], [12, 38], [38, 13], [13, 12],
  [70, 63], [63, 71], [71, 70], [31, 226], [226, 111], [111, 31],
  [36, 101], [101, 205], [205, 36], [203, 206], [206, 165], [165, 203],
  [126, 209], [209, 217], [217, 126], [98, 165], [165, 97], [97, 98],
  [237, 220], [220, 218], [218, 237], [237, 239], [239, 241], [241, 237],
  [210, 214], [214, 169], [169, 210], [140, 171], [171, 32], [32, 140],
  [241, 125], [125, 237], [237, 241], [179, 86], [86, 178], [178, 179],
  [180, 85], [85, 179], [179, 180], [181, 84], [84, 180], [180, 181],
  [182, 83], [83, 181], [181, 182], [194, 201], [201, 182], [182, 194],
  [177, 137], [137, 132], [132, 177], [184, 76], [76, 183], [183, 184],
  [185, 61], [61, 184], [184, 185], [186, 57], [57, 185], [185, 186],
  [216, 212], [212, 186], [186, 216], [192, 214], [214, 187], [187, 192],
  [139, 34], [34, 156], [156, 139], [218, 79], [79, 237], [237, 218],
  [147, 123], [123, 177], [177, 147], [45, 44], [44, 4], [4, 45],
  [208, 201], [201, 32], [32, 208], [98, 64], [64, 129], [129, 98],
  [192, 213], [213, 138], [138, 192], [235, 59], [59, 219], [219, 235],
  [141, 242], [242, 97], [97, 141], [97, 2], [2, 141], [141, 97],
  [240, 75], [75, 235], [235, 240], [229, 24], [24, 228], [228, 229],
  [31, 25], [25, 226], [226, 31], [230, 23], [23, 229], [229, 230],
  [231, 22], [22, 230], [230, 231], [232, 26], [26, 231], [231, 232],
  [233, 112], [112, 232], [232, 233], [244, 189], [189, 243], [243, 244],
  [189, 221], [221, 190], [190, 189], [222, 28], [28, 221], [221, 222],
  [223, 27], [27, 222], [222, 223], [224, 29], [29, 223], [223, 224],
  [225, 30], [30, 224], [224, 225], [113, 247], [247, 225], [225, 113],
  [99, 60], [60, 240], [240, 99], [213, 147], [147, 215], [215, 213],
  [60, 20], [20, 166], [166, 60], [192, 187], [187, 213], [213, 192],
  [243, 112], [112, 244], [244, 243], [244, 233], [233, 245], [245, 244],
  [245, 128], [128, 188], [188, 245], [188, 114], [114, 174], [174, 188],
  [134, 131], [131, 220], [220, 134], [174, 217], [217, 236], [236, 174],
  [236, 198], [198, 134], [134, 236], [215, 177], [177, 58], [58, 215],
  [156, 143], [143, 124], [124, 156], [25, 110], [110, 7], [7, 25],
  [31, 228], [228, 25], [25, 31], [264, 356], [356, 368], [368, 264],
  [0, 11], [11, 267], [267, 0], [451, 452], [452, 349], [349, 451],
  [267, 302], [302, 269], [269, 267], [350, 357], [357, 277], [277, 350],
  [350, 452], [452, 357], [357, 350], [299, 333], [333, 297], [297, 299],
  [396, 175], [175, 377], [377, 396], [280, 347], [347, 330], [330, 280],
  [269, 303], [303, 270], [270, 269], [151, 9], [9, 337], [337, 151],
  [344, 278], [278, 360], [360, 344], [424, 418], [418, 431], [431, 424],
  [270, 304], [304, 409], [409, 270], [272, 310], [310, 407], [407, 272],
  [322, 270], [270, 410], [410, 322], [449, 450], [450, 347], [347, 449],
  [432, 422], [422, 434], [434, 432], [18, 313], [313, 17], [17, 18],
  [291, 306], [306, 375], [375, 291], [259, 387], [387, 260], [260, 259],
  [424, 335], [335, 418], [418, 424], [434, 364], [364, 416], [416, 434],
  [391, 423], [423, 327], [327, 391], [301, 251], [251, 298], [298, 301],
  [275, 281], [281, 4], [4, 275], [254, 373], [373, 253], [253, 254],
  [375, 307], [307, 321], [321, 375], [280, 425], [425, 411], [411, 280],
  [200, 421], [421, 18], [18, 200], [335, 321], [321, 406], [406, 335],
  [321, 320], [320, 405], [405, 321], [314, 315], [315, 17], [17, 314],
  [423, 426], [426, 266], [266, 423], [396, 377], [377, 369], [369, 396],
  [270, 322], [322, 269], [269, 270], [413, 417], [417, 464], [464, 413],
  [385, 386], [386, 258], [258, 385], [248, 456], [456, 419], [419, 248],
  [298, 284], [284, 333], [333, 298], [168, 417], [417, 8], [8, 168],
  [448, 346], [346, 261], [261, 448], [417, 413], [413, 285], [285, 417],
  [326, 327], [327, 328], [328, 326], [277, 355], [355, 329], [329, 277],
  [309, 392], [392, 438], [438, 309], [381, 382], [382, 256], [256, 381],
  [279, 429], [429, 360], [360, 279], [365, 364], [364, 379], [379, 365],
  [355, 277], [277, 437], [437, 355], [282, 443], [443, 283], [283, 282],
  [281, 275], [275, 363], [363, 281], [395, 431], [431, 369], [369, 395],
  [299, 297], [297, 337], [337, 299], [335, 273], [273, 321], [321, 335],
  [348, 450], [450, 349], [349, 348], [359, 446], [446, 467], [467, 359],
  [283, 293], [293, 282], [282, 283], [250, 458], [458, 462], [462, 250],
  [300, 276], [276, 383], [383, 300], [292, 308], [308, 325], [325, 292],
  [283, 276], [276, 293], [293, 283], [264, 372], [372, 447], [447, 264],
  [346, 352], [352, 340], [340, 346], [354, 274], [274, 19], [19, 354],
  [363, 456], [456, 281], [281, 363], [426, 436], [436, 425], [425, 426],
  [380, 381], [381, 252], [252, 380], [267, 269], [269, 393], [393, 267],
  [421, 200], [200, 428], [428, 421], [371, 266], [266, 329], [329, 371],
  [432, 287], [287, 422], [422, 432], [290, 250], [250, 328], [328, 290],
  [385, 258], [258, 384], [384, 385], [446, 265], [265, 342], [342, 446],
  [386, 387], [387, 257], [257, 386], [422, 424], [424, 430], [430, 422],
  [445, 342], [342, 276], [276, 445], [422, 273], [273, 424], [424, 422],
  [306, 292], [292, 307], [307, 306], [352, 366], [366, 345], [345, 352],
  [268, 271], [271, 302], [302, 268], [358, 423], [423, 371], [371, 358],
  [327, 294], [294, 460], [460, 327], [331, 279], [279, 294], [294, 331],
  [303, 271], [271, 304], [304, 303], [436, 432], [432, 427], [427, 436],
  [304, 272], [272, 408], [408, 304], [395, 394], [394, 431], [431, 395],
  [378, 395], [395, 400], [400, 378], [296, 334], [334, 299], [299, 296],
  [6, 351], [351, 168], [168, 6], [376, 352], [352, 411], [411, 376],
  [307, 325], [325, 320], [320, 307], [285, 295], [295, 336], [336, 285],
  [320, 319], [319, 404], [404, 320], [329, 330], [330, 349], [349, 329],
  [334, 293], [293, 333], [333, 334], [366, 323], [323, 447], [447, 366],
  [316, 15], [15, 315], [315, 316], [331, 358], [358, 279], [279, 331],
  [317, 14], [14, 316], [316, 317], [8, 285], [285, 9], [9, 8],
  [277, 329], [329, 350], [350, 277], [253, 374], [374, 252], [252, 253],
  [319, 318], [318, 403], [403, 319], [351, 6], [6, 419], [419, 351],
  [324, 318], [318, 325], [325, 324], [397, 367], [367, 365], [365, 397],
  [288, 435], [435, 397], [397, 288], [278, 344], [344, 439], [439, 278],
  [310, 272], [272, 311], [311, 310], [248, 195], [195, 281], [281, 248],
  [375, 273], [273, 291], [291, 375], [175, 396], [396, 199], [199, 175],
  [312, 311], [311, 268], [268, 312], [276, 283], [283, 445], [445, 276],
  [390, 373], [373, 339], [339, 390], [295, 282], [282, 296], [296, 295],
  [448, 449], [449, 346], [346, 448], [356, 264], [264, 454], [454, 356],
  [337, 336], [336, 299], [299, 337], [337, 338], [338, 151], [151, 337],
  [294, 278], [278, 455], [455, 294], [308, 292], [292, 415], [415, 308],
  [429, 358], [358, 355], [355, 429], [265, 340], [340, 372], [372, 265],
  [352, 346], [346, 280], [280, 352], [295, 442], [442, 282], [282, 295],
  [354, 19], [19, 370], [370, 354], [285, 441], [441, 295], [295, 285],
  [195, 248], [248, 197], [197, 195], [457, 440], [440, 274], [274, 457],
  [301, 300], [300, 368], [368, 301], [417, 351], [351, 465], [465, 417],
  [251, 301], [301, 389], [389, 251], [394, 395], [395, 379], [379, 394],
  [399, 412], [412, 419], [419, 399], [410, 436], [436, 322], [322, 410],
  [326, 2], [2, 393], [393, 326], [354, 370], [370, 461], [461, 354],
  [393, 164], [164, 267], [267, 393], [268, 302], [302, 12], [12, 268],
  [312, 268], [268, 13], [13, 312], [298, 293], [293, 301], [301, 298],
  [265, 446], [446, 340], [340, 265], [280, 330], [330, 425], [425, 280],
  [322, 426], [426, 391], [391, 322], [420, 429], [429, 437], [437, 420],
  [393, 391], [391, 326], [326, 393], [344, 440], [440, 438], [438, 344],
  [458, 459], [459, 461], [461, 458], [364, 434], [434, 394], [394, 364],
  [428, 396], [396, 262], [262, 428], [274, 354], [354, 457], [457, 274],
  [317, 316], [316, 402], [402, 317], [316, 315], [315, 403], [403, 316],
  [315, 314], [314, 404], [404, 315], [314, 313], [313, 405], [405, 314],
  [313, 421], [421, 406], [406, 313], [323, 366], [366, 361], [361, 323],
  [292, 306], [306, 407], [407, 292], [306, 291], [291, 408], [408, 306],
  [291, 287], [287, 409], [409, 291], [287, 432], [432, 410], [410, 287],
  [427, 434], [434, 411], [411, 427], [372, 264], [264, 383], [383, 372],
  [459, 309], [309, 457], [457, 459], [366, 352], [352, 401], [401, 366],
  [1, 274], [274, 4], [4, 1], [418, 421], [421, 262], [262, 418],
  [331, 294], [294, 358], [358, 331], [435, 433], [433, 367], [367, 435],
  [392, 289], [289, 439], [439, 392], [328, 462], [462, 326], [326, 328],
  [94, 2], [2, 370], [370, 94], [289, 305], [305, 455], [455, 289],
  [339, 254], [254, 448], [448, 339], [359, 255], [255, 446], [446, 359],
  [254, 253], [253, 449], [449, 254], [253, 252], [252, 450], [450, 253],
  [252, 256], [256, 451], [451, 252], [256, 341], [341, 452], [452, 256],
  [414, 413], [413, 463], [463, 414], [286, 441], [441, 414], [414, 286],
  [286, 258], [258, 441], [441, 286], [258, 257], [257, 442], [442, 258],
  [257, 259], [259, 443], [443, 257], [259, 260], [260, 444], [444, 259],
  [260, 467], [467, 445], [445, 260], [309, 459], [459, 250], [250, 309],
  [305, 289], [289, 290], [290, 305], [305, 290], [290, 460], [460, 305],
  [401, 376], [376, 435], [435, 401], [309, 250], [250, 392], [392, 309],
  [376, 411], [411, 433], [433, 376], [453, 341], [341, 464], [464, 453],
  [357, 453], [453, 465], [465, 357], [343, 357], [357, 412], [412, 343],
  [437, 343], [343, 399], [399, 437], [344, 360], [360, 440], [440, 344],
  [420, 437], [437, 456], [456, 420], [360, 420], [420, 363], [363, 360],
  [361, 401], [401, 288], [288, 361], [265, 372], [372, 353], [353, 265],
  [390, 339], [339, 249], [249, 390], [339, 448], [448, 255], [255, 339],
];
